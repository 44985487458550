import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { combineLatest, filter } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { OrganizationState } from '../store/organization/organization.state';

export const organizationGuard: CanActivateFn = () => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const store = inject(Store);
  const toastr = inject(ToastrService);

  combineLatest([
    store.select(OrganizationState.organizations),
    toObservable(authService.checkingAuthState),
  ])
    .pipe(filter(([organization, user]) => !user && !!organization))
    .subscribe(([organizations]) => {
      if (!organizations?.length) {
        router.navigate(['/account/organizations']);
        const message = 'Lege zuerst deinen Verein an oder trete einem bei (:';
        if (!toastr.findDuplicate('', message, true, true)) {
          toastr.info(message);
        }
      }
    });
  return true;
};
